export const skills = {
  Frontend: [
    'Bootstrap 🅱️',
    'Figma 🎨',
    'Font Awesome 🔤',
    'JavaScript 📜',
    'Material UI 🧩',
    'React ⚛️',
    'Redux Toolkit 🛠️',
    'TypeScript 📝',
  ],
  Backend: [
    'AWS ☁️',
    'Cron jobs ⏲️',
    'Express.js 🚂',
    'Google Ads 📈',
    'Google Play 🎮',
    'JavaScript 📜',
    'JWT 🔐',
    'Logging with Morgan, Winston 📋',
    'MailerLite 📧',
    'Meta Conversion API 🔄',
    'Nginx 🌐',
    'Node.js 🟢',
    'Nodemailer 📬',
    'OAuth2 🔑',
    'Python 🐍',
    'Redis caching 🗃️',
    'ElastiCache 🗄️',
    'Testing with Jest, Supertest 🧪',
    'Tiktok Marketing API 📱',
    'TypeScript 📝',
    'Valkey caching 🗂️',
  ],
  Databases: [
    'Complex SQL queries with subqueries 🗃️',
    'In memory: Redis, Valkey, memcached 🧠',
    'knex 🔧',
    'Migrations 🛤️',
    'Non-relational: DynamoDB, MongoDB 📦',
    'ORMs: Objection, Prisma 🗄️',
    'Procedures 📜',
    'Relational: Postgres, MySQL 🗂️',
    'SQL transactions 💳',
  ],
  'System Design': [
    'Caching strategies 🗃️',
    'Classification system 🗂️',
    'Cron jobs ⏲️',
    'Data pipelines 🚰',
    'Database design and optimization 🗄️',
    'Hierarchical data storage and retrieval 🗂️',
    'Message queues 📬',
    'Microservices 🛠️',
    'Query optimization for large datasets 📊',
    'RESTful API design 🌐',
  ],
  'CI/CD': ['AWS CodeCommit ☁️', 'AWS CodeDeploy 🚀', 'AWS CodePipeline 🛠️', 'GitHub Actions ⚙️'],
  'Concepts & Technologies': [
    'Algorithms: Nested Set Model, Recursion 🔄',
    'Asynchronous programming ⏳',
    'Automation of a Dockerized application with Makefile scripting 🐳',
    'AWS API Gateway 🚪',
    'AWS Athena 📊',
    'AWS DynamoDB 📦',
    'AWS EC2 🖥️',
    'AWS Elasticache 🗄️',
    'AWS Glue 🧪',
    'AWS Lambda 🛠️',
    'AWS RDS 🗂️',
    'AWS S3 🗃️',
    'AWS SDK 📦',
    'Docker 🐳',
    'Elastic Load Balancer (ELB) ⚖️',
    'Functional Programming 🔧',
    'Git Version Control 🗂️',
    'Object Oriented Programming (OOP) 🧩',
  ],
};
