import { skills } from '../constants/skills';
import React from 'react';
import { Grid, Chip, Box, styled } from '@mui/material';

const SkillsSection = () => {
  const skillCategories = Object.keys(skills);

  const adjustColor = (color, amount) => {
    if (!color) {
      return '#808080';
    }
    return (
      '#' +
      color
        .replace(/^#/, '')
        .replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2))
    );
  };

  const GlowChip = styled(Chip)(({ backgroundColor }) => ({
    position: 'relative',
    boxShadow: `1px 1px 1px ${adjustColor(backgroundColor, -60)}80, 0px 0px 4px ${adjustColor(backgroundColor, -60)}80`,
    '&:hover': {
      transform: 'scale(1.1)',
      transition: 'transform 0.1s ease',
      zIndex: 1,
    },
    transition: 'transform 0.3s ease',
    backgroundColor: backgroundColor,
    color: '#000000',
  }));

  return (
    <section id='skills'>
      <details open>
        <summary>
          <h3>{'>'}Skills</h3>
        </summary>
        <Grid container spacing={2}>
          <div className='skills'>
            {skillCategories.map(category => (
              <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={category}>
                <Box sx={{ padding: '10px' }}>
                  <h3>{category}</h3>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '8px',
                      marginTop: '8px',
                    }}
                  >
                    {skills[category].map((skill, index) => {
                      return (
                        <GlowChip
                          key={skill}
                          label={skill}
                          backgroundColor={'#ffffff'}
                          sx={{
                            fontSize: '0.8rem',
                            padding: '4px 8px',
                          }}
                        />
                      );
                    })}
                  </Box>
                </Box>
              </Grid>
            ))}
          </div>
        </Grid>
      </details>
    </section>
  );
};

export default SkillsSection;
